import React from "react";
import Hero from "../components/Hero";
import Interduce from "../components/Interduce";
import Contact from "../components/Contact";

function Home() {
	return (
		<div>
			<Hero />
			{/* <Interduce />
			<Contact /> */}
		</div>
	);
}

export default Home;
