import React from "react";
import Type from "./Type";
import { FaGithub, FaLinkedin, FaXing, FaDiscord } from "react-icons/fa";
import { motion } from "framer-motion";
import {
	SiTypescript,
	SiDotnet,
	SiWordpress,
	SiReact,
	SiNodedotjs,
	SiDocker,
	SiMongodb,
	SiPostgresql,
} from "react-icons/si";

const iconVariants = {
	hover: {
		scale: 1.3,
		rotate: 360,
		transition: { duration: 0.5 },
	},
};
const buttonVariants = {
	hover: {
		scale: 1.1,
		transition: { duration: 0.3, yoyo: Infinity },
	},
};

function Hero() {
	return (
		<div className="bg-[url(../public/home-bg.jpg)] h-screen flex flex-col lg:flex-row items-center justify-center">
			<div className="w-full lg:w-1/2 h-1/2 lg:h-full flex items-center justify-center flex-col">
				<div className="flex gap-5 items-center lg:items-start justify-center flex-col">
					<div className="md:mb-12">
						<h3 className="flex text-white text-4xl gap-2">
							Hi There!
							<span>
								<img
									src="https://media.tenor.com/SNL9_xhZl9oAAAAi/waving-hand-joypixels.gif"
									alt="hand"
									className="h-10"
								/>
							</span>
						</h3>
						<span className="flex text-white text-4xl lg:text-5xl gap-2 font-bold">
							I'M <p className="text-[#ad50eb]"> MESSIE</p>
						</span>
					</div>
					<span className="flex text-[#ad50eb] text-2xl lg:text-3xl gap-2 font-semibold bg-gray-800 h-20 lg:h-32 w-72 lg:w-80 items-center justify-center px-5 rounded-md shadow-xl capitalize text-center">
						<Type />
					</span>

					<div className="flex space-x-4 sm:space-x-8 md:mb-12 md:mt-8 mt-4 z-10">
						<a
							href="https://github.com/moukmessie"
							target="_blank"
							rel="noopener noreferrer"
						>
							<motion.div variants={iconVariants} whileHover="hover">
								<FaGithub className="text-white w-6 h-6 sm:w-8 sm:h-8 md:hover:text-[#ad50eb]" />
							</motion.div>
						</a>
						<a
							href="https://www.linkedin.com/in/messiemoukimou"
							target="_blank"
							rel="noopener noreferrer"
						>
							<motion.div variants={iconVariants} whileHover="hover">
								<FaLinkedin className="text-white w-6 h-6 sm:w-8 sm:h-8 md:hover:text-[#ad50eb]" />
							</motion.div>
						</a>
						<a
							href="https://x.com/mgmouk"
							target="_blank"
							rel="noopener noreferrer"
						>
							<motion.div variants={iconVariants} whileHover="hover">
								<FaXing className="text-white w-6 h-6 sm:w-8 sm:h-8 md:hover:text-[#ad50eb]" />
							</motion.div>
						</a>
						<a
							href="https://discord.gg/4ghE24tc"
							target="_blank"
							rel="noopener noreferrer"
						>
							<motion.div variants={iconVariants} whileHover="hover">
								<FaDiscord className="text-white w-6 h-6 sm:w-8 sm:h-8 md:hover:text-[#ad50eb]" />
							</motion.div>
						</a>
					</div>
				</div>
			</div>

			{/* Section pour l'image et les icônes */}
			<div className="w-full lg:w-1/2 h-full flex flex-col items-center justify-center mt-4 lg:mt-0">
				<img src="mg-mouk.png" alt="" className="md:h-[45vh] " />

				<div className=" left-4 sm:left-12 flex space-x-4 sm:space-x-8 z-10 mt-[3rem]">
					<motion.div variants={iconVariants} whileHover="hover">
						<SiTypescript className="md:text-[#ad50eb] text-white w-8 h-8 sm:w-12 sm:h-12 hover:scale-125 transition duration-300 hover:text-white" />
					</motion.div>
					<motion.div variants={iconVariants} whileHover="hover">
						<SiDotnet className="md:text-[#ad50eb] text-white w-8 h-8 sm:w-12 sm:h-12 hover:scale-125 transition duration-300 hover:text-white" />
					</motion.div>
					<motion.div variants={iconVariants} whileHover="hover">
						<SiWordpress className="md:text-[#ad50eb] text-white w-8 h-8 sm:w-12 sm:h-12 hover:scale-125 transition duration-300 hover:text-white" />
					</motion.div>
					<motion.div variants={iconVariants} whileHover="hover">
						<SiReact className="md:text-[#ad50eb] text-white w-8 h-8 sm:w-12 sm:h-12 hover:scale-125 transition duration-300 hover:text-white" />
					</motion.div>
					<motion.div variants={iconVariants} whileHover="hover">
						<SiNodedotjs className="md:text-[#ad50eb] text-white  w-8 h-8 sm:w-12 sm:h-12 hover:scale-125 transition duration-300 hover:text-white" />
					</motion.div>
					<motion.div variants={iconVariants} whileHover="hover">
						<SiDocker className="md:text-[#ad50eb] text-white w-8 h-8 sm:w-12 sm:h-12 hover:scale-125 transition duration-300 hover:text-white" />
					</motion.div>
					<motion.div variants={iconVariants} whileHover="hover">
						<SiMongodb className="md:text-[#ad50eb] text-white w-8 h-8 sm:w-12 sm:h-12 hover:scale-125 transition duration-300 hover:text-white" />
					</motion.div>
					<motion.div variants={iconVariants} whileHover="hover">
						<SiPostgresql className="md:text-[#ad50eb] text-white w-8 h-8 sm:w-12 sm:h-12 hover:scale-125 transition duration-300  md:hover:text-white" />
					</motion.div>
				</div>

				{/* Bouton Portfolio au centre */}
				<div className=" mt-8">
					<a href="mailto:moukmessie@gmail.com">
						<motion.button
							className="border border-white text-white font-bold py-2 px-4 sm:px-6 sm:py-2 hover:bg-white hover:text-black transition duration-300 text-base sm:text-lg"
							variants={buttonVariants}
							whileHover="hover"
						>
							Let's collaborate
						</motion.button>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Hero;
