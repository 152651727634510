import React from "react";
import { Typewriter } from "react-simple-typewriter";
function Type() {
	return (
		<div>
			<Typewriter
				words={[
					"Software Engineer",
					"Full-Stack Developer",
					"DevOps Enthusiast",
					"AI Enthusiast",
					"SaaS Solution Creator",
					"System Architect",
					"Innovator",
					"Performance Optimizer",
					"Freelancer",
				]}
				loop={0}
				cursor
				cursorStyle="|"
				typeSpeed={70}
				deleteSpeed={50}
				delaySpeed={1000}
			/>
		</div>
	);
}

export default Type;
